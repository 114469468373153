:root {
  --box-color: rgb(94, 0, 27);
  --field-color: rgb(247, 185, 254);
}

body {
  background: linear-gradient(#ea7171, #d97ee9);
}
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}
.header {
  height: 32px;
  background-color: var(--box-color);
  color: white;
  font-weight: bold;
  padding-left: 12px;
  padding-top: 12px;
  box-shadow: 2px 2px 10px black;
  position: relative;
}

#editor, #previewField, .header {
  border: 1px solid var(--box-color);
  box-shadow: 2px 2px 4px black;
}

#editor, #previewField {
  background-color: var(--field-color);
}

#editor {
  padding: 12px;
}

#editField .toolbar {
  width: 99%;
}

#previewField {
  margin-top: 16px;
  width: 800px;
}

#preview {
  padding: 12px;
}

.icon {
  display: inline;
  text-align: right;
  position: absolute;
  right: 0;
  padding-right: 12px;
}

.icon:hover {
  color: rgb(253, 105, 51);
}

.fullView {
  min-height: 100vh;
}

.hide {
  display: none;
}
